import Zen from '../core';
import './pieces';

/**
 * @class A Pieces
 * @extends {Zen}
 */
Zen.Pieces.Piece = function (samples) {
    Zen.call(this);
    this._className = 'Zen.Pieces.Piece';

    /**
     * Base volume of the piece
     *  @type {Number}
     *  @private
     */
    this._volume = 0;

    /**
     * Collection of samples
     *  @type {Object}
     *  @private
     */
    this._samples = samples;

    /**
     *  @type {Boolean}
     *  @private
     */
    this._playing = false;

    /**
     *  @type {Function}
     *  @private
     */
    this._toneCall = false;

    /**
     * Piece name
     *  @type {String}
     *  @private
     */
    this._name = '';

    /**
     * Artist name
     *  @type {String}
     *  @private
     */
    this._artist = 'unknown artist';

    /**
     * Vinyl image pack
     *  @type {String}
     *  @private
     */
    this._vinyl = 'default';
};

Zen.extend(Zen.Pieces.Piece);

/**
 * Starts playing the piece
 * @param {Tone.Channel} destination
 */
Zen.Pieces.Piece.prototype.play = function(destination) {
    this.info('Playing piece');
    this._playing = true;
    return this._initPiece(destination).then(() => {
    });
};

/**
 * Stops playing the piece
 */
Zen.Pieces.Piece.prototype.stop = function() {
    this.info('Stopping piece');
    this._playing = false;
    this._stopped = Date.now();
};

/**
 * Stops playing the piece
 */
Zen.Pieces.Piece.prototype.cleanup = function() {
    this.info('Cleaning up');
    this._cleanupPiece();
};

/**
 * Initializes Piece (and starts playing immediatelly)
 * @private
 * @param {Tone.Channel} destination
 * @return {Promise} Promise that will be fulfilled once the Piece is
 * initialized.
 */
Zen.Pieces.Piece.prototype._initPiece = function(destination) {
    return Promise.resolve(() => {
    });
};

/**
 * Cleans up the piece.
 * @private
 */
Zen.Pieces.Piece.prototype._cleanupPiece = function() {
};

/**
 *  Executes a function only if "playing"
 *  @param {Function} f Function to call
 */
Zen.Pieces.Piece.prototype.toneCall = function(f) {
    if (this._playing) {
        f();
    }
};

/**
 * @return {String} Piece name
 */
Zen.Pieces.Piece.prototype.getName = function() {
    return this._name;
};

/**
 * @return {String} Artist name
 */
Zen.Pieces.Piece.prototype.getArtist = function() {
    return this._artist;
};

/**
 * @return {Number} Volume
 */
Zen.Pieces.Piece.prototype.getVolume = function() {
    return this._volume;
};

/**
 * @return {String} Vinyl image pack
 */
Zen.Pieces.Piece.prototype.getVinyl = function() {
    return this._vinyl;
};

export default Zen.Pieces.Piece;
