import Tone from 'tone';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Beneath Waves
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.BeneathWaves = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.BeneathWaves';

  // Override the volume
  this._volume = -15;

  this._name = 'Beneath Waves';
  this._artist = 'Alex Bainter';
  this._vinyl = 'beneath_waves';
};

Zen.extend(Zen.Pieces.BeneathWaves, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.BeneathWaves.prototype._initPiece = function(destination) {
  return Promise.all([
    Zen.MusicUtils.getBuffers(this._samples['sso-chorus-female']),
    Zen.MusicUtils.getBuffers(this._samples['sso-cor-anglais']),
    new Tone.Reverb(15).generate(),
  ]).then(([chorus, corAnglais, reverb]) => {
    const compressor = new Tone.Compressor().connect(destination);
    const synthVol = new Tone.Volume().connect(compressor);

    const synthVolumeLfo = new Tone.LFO(
      Math.random() / 100 + 0.01,
      -30,
      -5
    ).set({
      phase: 90,
    });
    synthVolumeLfo.connect(synthVol.volume);
    synthVolumeLfo.start();

    const delay = new Tone.FeedbackDelay(2, 0.7).connect(synthVol);
    const lPan = new Tone.Panner(-1).connect(delay);
    const rPan = new Tone.Panner(1).connect(delay);
    const lSynth = new Tone.Synth({
      oscillator: { type: 'sine' },
    }).connect(lPan);
    const rSynth = new Tone.Synth({
      oscillator: { type: 'sine' },
    }).connect(rPan);

    const sub = () => {
      if (this._playing) {
        lSynth.triggerAttackRelease(38, 0.5, '+1');
      }
      if (this._playing) {
        rSynth.triggerAttackRelease(38, 0.5, '+1.5');
      }
      if (this._playing) {
        Tone.Transport.scheduleOnce(() => {
          sub();
        }, '+8');
      }
    };

    sub();

    reverb.connect(compressor);
    const autoFilter = new Tone.AutoFilter(Math.random() / 100 + 0.01, 75, 6);
    autoFilter.connect(reverb);
    autoFilter.start();
    const playbackRate = 0.15;
    const vol = new Tone.Volume(-10);
    vol.connect(autoFilter);

    const activeSources = [];

    const play = notes => {
      if (!this._playing) {
        return;
      }
      const note = notes[Math.floor(Math.random() * notes.length)];
      if (chorus === null) {
        return;
      }
      const buf = chorus.get(note);
      const source = new Tone.BufferSource(buf)
        .set({
          playbackRate,
          fadeIn: 4,
          fadeOut: 4,
          curve: 'linear',
          onended: () => {
            const i = activeSources.indexOf(source);
            if (i > -1) {
              let n = activeSources[i];
              activeSources.splice(i, 1);
              n.dispose();
            }
          },
        })
        .connect(vol);
      source.start('+1', 0, buf.duration / playbackRate);

      activeSources.push(source);

      if (this._playing) {
        Tone.Transport.scheduleOnce(() => {
          play(notes);
        }, `+${buf.duration / playbackRate - (1 + Math.random() * 5)}`);
      }
    };

    const playCorAnglais = () => {
      if (!this._playing) {
        return;
      }

      const note = `F${Math.floor(Math.random() * 2) + 3}`;
      if (corAnglais === null) {
        return;
      }
      const buf = corAnglais.get(note);
      const source = new Tone.BufferSource(buf)
        .set({
          playbackRate: 0.15,
          fadeIn: 4,
          fadeOut: 4,
          curve: 'linear',
          onended: () => {
            const i = activeSources.indexOf(source);
            if (i > -1) {
              let n = activeSources[i];
              activeSources.splice(i, 1);
              n.dispose();
            }
          },
        })
        .connect(vol);
      source.start('+1');
      activeSources.push(source);

      if (this._playing) {
        Tone.Transport.scheduleOnce(() => {
          playCorAnglais();
        }, `+${buf.duration * 10 + Math.random() * buf.duration * 10}`);
      }
    };

    if (this._playing) {
      Tone.Transport.scheduleOnce(() => {
        playCorAnglais();
      }, `+${Math.random() * 60}`);
    }

    play(['C5']);
    play(['C6']);

    this._cleanupPiece = () => {
      var i = 0;
      activeSources.forEach(node => {
          node.dispose();
        }
      );
      [reverb, autoFilter, vol, chorus, corAnglais].forEach(node => {
          node.dispose();
        }
      );
      chorus = corAnglais = null;
    };
  });
};

export default Zen.Pieces.BeneathWaves;
