import Tone from 'tone';
import Zen from '../core';

const samplesByFormat = require('../../../dist/samples/index.json');

function transformUrls(transform, sampleIndex) {
  return Object.assign(
    {},
    Reflect.ownKeys(sampleIndex).reduce((prefixedIndex, sampleName) => {
      const samples = sampleIndex[sampleName];
      prefixedIndex[sampleName] = Array.isArray(samples)
        ? samples.map(url => transform(url))
        : Reflect.ownKeys(samples).reduce((prefixedSamples, key) => {
            prefixedSamples[key] = transform(samples[key]);
            return prefixedSamples;
          }, {});
      return prefixedIndex;
    }, {})
  );
}

Zen.MusicUtils = {};

Zen.MusicUtils.getSamplesByFormat = function(sampleFileHostArg) {
  const host = sampleFileHostArg || process.env.SAMPLE_FILE_HOST;

  if (!host) {
    return samplesByFormat;
  }
  const prefixUrl = url => `${host}/${url}`;

  return Reflect.ownKeys(samplesByFormat).reduce(
    (prefixedSamplesByFormat, format) => {
      prefixedSamplesByFormat[format] = transformUrls(
        prefixUrl,
        samplesByFormat[format]
      );
      return prefixedSamplesByFormat;
    },
    {}
  );
}

Zen.MusicUtils.getBuffer = url =>
  url instanceof AudioBuffer
    ? Promise.resolve(new Tone.Buffer(url))
    : new Promise(resolve => {
        const buffer = new Tone.Buffer(url, () => {
          resolve(buffer);
        });
      });

      Zen.MusicUtils.getBuffers = urlMap => {
  const urls = Array.isArray(urlMap) ? urlMap : Object.values(urlMap);
  if (urls.every(url => url instanceof AudioBuffer)) {
    return Promise.resolve(new Tone.Buffers(urlMap));
  }
  return new Promise(resolve => {
    const buffers = new Tone.Buffers(urlMap, {
      onload: () => resolve(buffers),
    });
  });
};

Zen.MusicUtils.getSampler = (urlMap, opts = {}) => {
  const urls = Array.isArray(urlMap) ? urlMap : Object.values(urlMap);
  if (urls.every(url => url instanceof AudioBuffer)) {
    return Promise.resolve(new Tone.Sampler(urlMap, opts));
  }
  return new Promise(resolve => {
    const sampler = new Tone.Sampler(
      urlMap,
      Object.assign({}, opts, {
        onload: () => resolve(sampler),
      })
    );
  });
};

export default Zen.MusicUtils;
