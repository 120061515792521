import Tone from 'tone';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Uun
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.Uun = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.Uun';

  this._name = 'Uun';
  this._artist = 'Alex Bainter';
  this._vinyl = 'uun';

  this.transpose = (note, semitones) => {
    const pcTranspose = (note, semitones) => {
      const NOTES = [
        'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

      const currentIndex = NOTES.indexOf(note);
      const naiveIndex = currentIndex + semitones;
      const octaveChange = Math.floor(naiveIndex / 12);
      const realIndex =
        naiveIndex >= 0 ? naiveIndex % 12 : (12 + (naiveIndex % 12)) % 12;
      return [NOTES[realIndex], octaveChange];
    };

    const matches = /([A,B,C,D,E,F,G,#]{1,2})(\d*)/.exec(note);
    if (matches !== null) {
      // eslint-disable-next-line no-unused-vars
      const [_, pc, octaveStr] = matches;
      const [newPc, octaveChange] = pcTranspose(pc, semitones);
      if (octaveStr) {
        return `${newPc}${Number(octaveStr) + octaveChange}`;
      }
      return newPc;
    }
    return note;
  };

  const makeNoteGenerator = function*(notes) {
    for (
      let i = 0;
      i < notes.length;
      i === notes.length - 1 ? (i = 0) : (i += 1)
    ) {
      yield notes[i];
    }
  };

  const trillNoteSets = [['D5', 'C5'], ['D#5', 'D5'], ['F5', 'D#5']];

  this.trillGenerators = trillNoteSets.map(notes => makeNoteGenerator(notes));
};

Zen.extend(Zen.Pieces.Uun, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.Uun.prototype._initPiece = function(destination) {
  return Zen.MusicUtils.getSampler(
    this._samples['vsco2-piano-mf']
  ).then(piano => {
    piano.connect(destination);
    const splatterNotes = transposeUp => {
      const multiplier = Math.pow(Math.random(), 2) * 0.5 + 0.01;
      ['C3', 'D#3', 'G3', 'A#3', 'D4']
        .map(note => (transposeUp ? this.transpose(note, 5) : note))
        .forEach((note, i) => {
          if (this._playing) {
            piano.triggerAttack(
              note,
              `+${1 + multiplier * i + Math.random() / 50 - 0.01}`
            );
          };
        });
    };

    const trillNotes = transposeUp => {
      const trillerGeneratorIndex = Math.floor(
        Math.random() * this.trillGenerators.length
      );
      const trillGenerator = this.trillGenerators[trillerGeneratorIndex];

      const trill = Array.from({
        length: Math.ceil(Math.random() * 8) + 12,
      })
        .map(() => trillGenerator.next().value)
        .map(note =>
          transposeUp && trillGenerator !== 3 ? this.transpose(note, 5) : note
        );
      const upper = Math.random() * 0.5 + 0.4;
      const lower = Math.random() * 0.1 + 0.2;
      const getNoteWaitTime = x =>
        -4 * (lower - upper) * Math.pow(x, 2) + 4 * (lower - upper) * x + upper;
      const lastTrillTime = (Math.random() < 0.5
        ? trill
        : trill.reverse()
      ).reduce((lastNoteTime, note, i) => {
        const noteTime = lastNoteTime + getNoteWaitTime(i / (trill.length - 1));
        if (this._playing) {
          piano.triggerAttack(
            note,
            `+${noteTime + Math.random() / 50 - 0.01}`,
            0.5
          );
        };
        return noteTime;
      }, 1 - upper);

      return lastTrillTime;
    };

    const playMoment = () => {
        if (!this._playing) {
          return;
        }
        const up = Math.random() < 0.5;
        splatterNotes(up);
        const lastTrillTime = trillNotes(up);

        if (this._playing) {
          Tone.Transport.scheduleOnce(() => {
            playMoment();
          }, `+${Math.random() * 5 + lastTrillTime - 0.5}`);
        };
    };

    playMoment();

    this._cleanupPiece = () => {
      piano.dispose();
      piano = null;
    };
  });
};

export default Zen.Pieces.Uun;
