import Tone from 'tone';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Stratospheric
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.Stratospheric = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.Stratospheric';

  this._name = 'Stratospheric';
  this._artist = 'Alex Bainter';
  this._vinyl = 'stratospheric';

  this.activeSources = [];

  this.getBufferPlayer = (bufferUrls, buffers, bufferDestination, getP) => {
    if (buffers == null) {
        return;
    }
    const firstDelays = bufferUrls.map(() => Math.random() * 90);
    const minFirstDelay = Math.min(...firstDelays);
    bufferUrls
      .map((url, i) => buffers.get(i))
      .forEach((buffer, i) => {
        const play = () => {
          if (!this._playing) {
              return;
          }
          if (Math.random() < getP()) {
            const source = new Tone.BufferSource(buffer).set({
              playbackRate: 0.75,
              onended: () => {
                const sourceIndex = this.activeSources.indexOf(source);
                if (sourceIndex >= 0) {
                  this.activeSources.splice(sourceIndex, 1);
                }
              },
            });
            this.activeSources.push(source);
            if (bufferDestination != null) {
                source.connect(bufferDestination);
                source.start('+1');
            }
          }

          Tone.Transport.scheduleOnce(() => {
            play();
          }, `+${Math.random() * 60 + 30}`);
        };
        const firstDelay = firstDelays[i] - minFirstDelay;
        if (this._playing) {
            Tone.Transport.scheduleOnce(() => {
              play();
            }, `+${firstDelay}`);
        }
      });
  };
};

Zen.extend(Zen.Pieces.Stratospheric, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.Stratospheric.prototype._initPiece = function(destination) {
  const coilSpankUrls = this._samples['guitar-coil-spank'];
  const dustyUrls = this._samples['guitar-dusty'];

  return Promise.all([
    Zen.MusicUtils.getBuffers(coilSpankUrls),
    Zen.MusicUtils.getBuffers(dustyUrls),
    new Tone.Reverb(30)
      .set({ wet: 0.6 })
      .connect(destination)
      .generate(),
  ]).then(([coilSpankBuffers, dustyBuffers, reverb]) => {
    var dustyVol = new Tone.Volume(-7).connect(reverb);
    const getCoilSpankP = () => 1 - ((Tone.now() / 60) % 60) / 60;
    const getDustyP = () => 1 - getCoilSpankP();
    this.getBufferPlayer(coilSpankUrls, coilSpankBuffers, reverb, getCoilSpankP);
    this.getBufferPlayer(dustyUrls, dustyBuffers, dustyVol, getDustyP);
    this._cleanupPiece = () => {
	  coilSpankBuffers.dispose();
	  coilSpankBuffers = null;
	  dustyBuffers.dispose();
	  dustyBuffers = null;
	  reverb.dispose();
	  reverb = null;
	  dustyVol.dispose();
	  dustyVol = null;
      this.activeSources.forEach(node => node.dispose());
	};
  });
};

export default Zen.Pieces.Stratospheric;
