import Tone from 'tone';
import { Distance, Note } from 'tonal';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Ritual
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.Ritual = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.Ritual';

  this._name = 'Ritual';
  this._artist = 'Alex Bainter';
  this._vinyl = 'ritual';

  const findClosest = (note, samplesByNote) => {
    const noteMidi = Note.midi(note);
    const maxInterval = 96;
    let interval = 0;
    while (interval <= maxInterval) {
      const higherNote = Note.fromMidi(noteMidi + interval);
      if (samplesByNote[higherNote]) {
        return higherNote;
      }
      const lowerNote = Note.fromMidi(noteMidi - interval);
      if (samplesByNote[lowerNote]) {
        return lowerNote;
      }
      interval += 1;
    }
    return note;
  };

  this.getCustomSampler = (samplesByNote, semitoneChange = 24) => {
    const activeSources = [];
    let destination;
    return Zen.MusicUtils.getBuffers(samplesByNote).then(buffers => ({
      triggerAttack: (note, time = Tone.now()) => {
        const closestSample = findClosest(note, samplesByNote);
        const difference = Distance.semitones(closestSample, note);
        const buffer = buffers.get(closestSample);
        const playbackRate = Tone.intervalToFrequencyRatio(
          difference - semitoneChange + Math.random() * 0.1 - 0.05
        );
        const bufferSource = new Tone.BufferSource(buffer)
          .set({
            playbackRate,
            onended: () => {
              const i = activeSources.indexOf(bufferSource);
              if (i >= 0) {
                let n = activeSources[i];
                activeSources.splice(i, 1);
                n.dispose();
              }
            },
          })
          .connect(destination);
        activeSources.push(bufferSource);
        bufferSource.start(time);
      },
      dispose: () => {
        [buffers, ...activeSources].forEach(node => node.dispose());
      },
      connect: dest => {
        destination = dest;
      },
    }));
  };

  this.getPercussionSampler = sampleUrls =>
  Zen.MusicUtils.getBuffers(sampleUrls).then(buffers => {
      const activeSources = [];
      let destination;
      return {
        triggerAttack: time => {
          if (buffers === null) {
            return;
          }
          const buffer = buffers.get(
            Math.floor(Math.random() * sampleUrls.length)
          );
          const source = new Tone.BufferSource(buffer)
            .set({
              onended: () => {
                const i = activeSources.indexOf(source);
                if (i >= 0) {
                  let n = activeSources[i];
                  activeSources.splice(i, 1);
                  n.dispose();
                }
              },
            })
            .connect(destination);
          source.start(time);
        },
        connect: dest => {
          destination = dest;
        },
        dispose: () => {
          activeSources.forEach(node => {
            node.dispose();
          });
          buffers.dispose();
          buffers = null;
        },
      };
    });

  this.violinPhrases = [['G#4', 'F4', 'F#4', 'C#4'], ['A#4', 'F#4', 'G#4']];
};

Zen.extend(Zen.Pieces.Ritual, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.Ritual.prototype._initPiece = function(destination) {
  const didgeridooSamples = this._samples['vcsl-didgeridoo-sus'];
  return Promise.all([
    Zen.MusicUtils.getBuffers(didgeridooSamples),
    new Tone.Reverb(30).connect(destination).generate(),
    this.getCustomSampler(this._samples['vsco2-violins-susvib']),
    this.getPercussionSampler(this._samples['vcsl-bassdrum-hit-ff']),
    new Tone.Reverb(15)
      .set({ wet: 0.5 })
      .connect(destination)
      .generate(),
    ...[1, 2, 3, 4, 5].map(id =>
      this.getPercussionSampler(this._samples[`vcsl-darbuka-${id}-f`])
    ),
  ]).then(
    ([buffers, reverb, violins, bassdrum, percussionReverb, ...darbukas]) => {
      const drone = () => {
        if (!this._playing) {
          return;
        }

        const buffer = buffers.get(
          Math.floor(Math.random() * didgeridooSamples.length)
        );
        const playbackRate = Math.random() < 0.9 ? 1 : 0.5;
        const source = new Tone.BufferSource(buffer)
          .set({ fadeIn: 5, playbackRate })
          .connect(reverb);
        source.start('+1');
        if (this._playing) {
          Tone.Transport.scheduleOnce(() => {
            drone();
          }, `+${1 + buffer.duration / 3 / playbackRate}`);
        }
      };
      drone();
      violins.connect(reverb);

      const playViolinPhrase = () => {
        if (!this._playing) {
          return;
        }
        const phrase =
        this.violinPhrases[Math.floor(Math.random() * this.violinPhrases.length)];

        const totalDelay = phrase.reduce((delay, note) => {
          violins.triggerAttack(note, `+${delay}`);
          return delay + Math.random() * 20 + 20;
        }, Math.random() * 5);

        if (this._playing) {
          Tone.Transport.scheduleOnce(() => {
            playViolinPhrase();
          }, `+${totalDelay + 10}`);
        }
      };

      playViolinPhrase();

      const percussionVol = new Tone.Volume().connect(percussionReverb);
      bassdrum.connect(percussionVol);

      const percussionVolLfo = new Tone.LFO(
        Math.random() / 1000 + 0.001,
        -200,
        -10
      ).set({ phase: 90 });
      percussionVolLfo.connect(percussionVol.volume);
      percussionVolLfo.start();

      darbukas.forEach(darbuka => {
        darbuka.connect(percussionVol);
      });

      const percussion = (beatTime, up) => {
        if (!this._playing) {
          return;
        }

        if (this._playing) {
          bassdrum.triggerAttack('+1');
        }
        if (this._playing) {
          bassdrum.triggerAttack(`+${1 + beatTime * 2}`);
        }

        for (let i = 0; i < 32; i += 1) {
          if (i === 0 || i === 2 || Math.random() < 0.3) {
            darbukas[Math.floor(Math.random() * darbukas.length)].triggerAttack(
              `+${1 + beatTime * i}`
            );
          }
        }

        if (this._playing) {
          Tone.Transport.scheduleOnce(() => {
            if (up && beatTime > 0.6) {
              percussion(beatTime * (1 - Math.random() * 0.02), false);
            } else if (!up && beatTime < 0.2) {
              percussion(beatTime * (1 + Math.random() * 0.02), true);
            } else if (up) {
              percussion(beatTime * (1 + Math.random() * 0.02), true);
            } else {
              percussion(beatTime * (1 - Math.random() * 0.02), false);
            }
          }, `+${32 * beatTime}`);
        }
      };

      percussion(Math.random() * 0.4 + 0.2, true);

      this._cleanupPiece = () => {
        [
          reverb,
          violins,
          bassdrum,
          percussionReverb,
          percussionVol,
          percussionVolLfo,
          ...darbukas,
        ].forEach(node => {
          node.dispose();
        });
        buffers.dispose();
      };
    }
  );
};

export default Zen.Pieces.Ritual;
