import Tone from 'tone';
import Zen from './core';

/**
 * Crossfade time, in seconds
 * @constant
 * @type {Number}
 * @private
 */
const CROSSFADE_TIME = 5.0;

/**
 * Additional time, in seconds, after which to stop a piece after crossfade is
 * done. This is a tradeoff between performance (CPU/memory) and a smooth
 * crossfade.
 * @constant
 * @type {Number}
 * @private
 */
const STOP_DELAY = 5.0;

/**
 * Additional time, in seconds to wait after stopping, before disposing other
 * resources. This is a tradeoff between performance (CPU/memory) and having
 * concurrency issues (late-scheduled notes played on destroyed resources).
 * @constant
 * @type {Number}
 * @private
 */
const DISPOSE_DELAY = 30.0;

/**
 * @class Piece context
 * @param {Zen.Pieces.Piece} piece
 * @param {Number} index Position in pieces list
 * @param {Tone.Channel} channel Channel that piece uses for its output
 * @extends {Zen}
 */
Zen.PieceContext = function (piece, index, channel) {
    Zen.call(this);
    this._className = 'Zen.PieceContext';
    this.piece = piece;
    this.index = index;
    this.channel = channel;
};

Zen.extend(Zen.PieceContext);

/**
 * @class Player for playing the Pieces
 * @param {Array} pieces A list of {Zen.Pieces.Piece} to play
 * @param {Object} samples A set of samples
 * @param {Tone.Channel} destination Where to output music to
 * @param {Number} pieceInterval Interval at which to move to the next piece,
 *                               0 to disable
 * @param {Function} onStateChange Function to call when state changes
 *                                 (e.g. play/stop)
 * @extends {Zen}
 */
Zen.PiecesPlayer = function (pieces, samples, destination, pieceInterval, onStateChange) {
    Zen.call(this);
    this._className = 'Zen.PiecesPlayer';

    /**
     *  Array of Piece constructors
     *  @type {Array}
     *  @private
     */
    this._pieces = pieces;

    /**
     *  Current piece
     *  @type {Number}
     *  @private
     */
    this._currentPieceIndex = null;

    /**
     *  Index of last played piece before stopping.
     *  @type {Number}
     *  @private
     */
    this._lastPlayedIndex = null;

    /**
     *  External channel to play on (input)
     *  @type {Tone.Channel}
     *  @private
     */
    this._destination = destination;

    /**
     *  Crossfader connected to '_destination', used to fade between two pieces
     *  @type {Tone.CrossFade}
     *  @private
     */
    this._crossFade = new Tone.CrossFade(0);
    this._crossFade.connect(this._destination);

    /**
     *  Which input of the crossfader is currently in use (0 or 1)
     *  @type {Tone.Channel}
     *  @private
     */
    this._currentCrossFadeIndex = 0;

    /**
     *  Function to invoke on state change
     *  @type {Function}
     *  @private
     */
    this._onStateChangeCallback = onStateChange;

    /**
     *  If > 0, time in seconds after which to expire a piece.
     *  @type {Number}
     *  @private
     */
    this._pieceInterval = pieceInterval;

    /**
     *  Auto-incremented counter, tracking number of state changes.
     *  @type {Number}
     *  @private
     */
    this._stateCounter = 0;

    /**
     * Collection of samples
     *  @type {Object}
     *  @private
     */
    this._samples = samples;

    /**
     *  Current piece context.
     *  @type {Zen.PieceContext}
     *  @private
     */
    this._activeContext = null;

    /**
     *  Unique timestamp of when the "next song" action was last scheduled.
     *  @type {Number}
     *  @private
     */
    this._lastScheduled = 0;
};

Zen.extend(Zen.PiecesPlayer);

Zen.PiecesPlayer.prototype.playPiece = function(pieceIndex) {
    if (this._getIndex() === pieceIndex) {
        this.warn('Same piece already playing, index = ' + pieceIndex);
        return Promise.resolve(false);
    }

    var pieceCtor = this._pieces[pieceIndex];
    var piece = new pieceCtor(this._samples);
    var channel = new Tone.Channel(piece.getVolume());
    return piece.play(channel).then(() => {
        this._currentCrossFadeIndex = (this._currentCrossFadeIndex + 1) % 2;
        channel.connect(
            this._crossFade, 0, this._currentCrossFadeIndex);
        this._crossFade.fade.exponentialRampTo(
            this._currentCrossFadeIndex, CROSSFADE_TIME);

        let previousContext = this._activeContext;
        this._setActiveContext(piece, pieceIndex, channel);
        this._stopPiece(previousContext, CROSSFADE_TIME + STOP_DELAY);
        return true;
    });
};

Zen.PiecesPlayer.prototype.resume = function() {
    if (this._lastPlayedIndex === null) {
        this.error('Cant resume - nothing was playing since creation');
        return;
    }

    if (this._getIndex() === this._lastPlayedIndex) {
        this.warn('Nothing to resume - piece already playing');
        return;
    }
    return this.playPiece(this._lastPlayedIndex);
};

Zen.PiecesPlayer.prototype.next = function() {
    var pieceIndex = (this._getIndex() + 1) % this._pieces.length;
    return this.playPiece(pieceIndex);
};

Zen.PiecesPlayer.prototype.previous = function() {
    var total = this._pieces.length;
    var pieceIndex = (total + this._getIndex() - 1) % total;
    return this.playPiece(pieceIndex);
};

Zen.PiecesPlayer.prototype.stop = function() {
    this._lastPlayedIndex = this._getIndex();
    if (this._activeContext !== null) {
        let previousContext = this._activeContext;
        this._setActiveContext(null, null, null);
        this._stopPiece(previousContext, 0);
    }
};

Zen.PiecesPlayer.prototype.getState = function() {
    var playing = (this._activeContext != null);
    return {
        playing: playing,
        piece: playing ? this._activeContext.piece.getName() : '',
        artist: playing ? this._activeContext.piece.getArtist() : '',
        vinyl: playing ? this._activeContext.piece.getVinyl() : '',
        pieceIndex: playing ? this._activeContext.index : -1,
    };
};

Zen.PiecesPlayer.prototype._stopPiece = function(pieceContext, stopDelay) {
    if (pieceContext == null) {
        return;
    }
    var piece = pieceContext.piece;
    var channel = pieceContext.channel;
    var stopFunction = () => {
        // Called after crossfade is fully done.
        piece.stop();
        channel.disconnect();
        channel.dispose();
        setTimeout(() => {
            // Called after a few more seconds, to reduce chance of concurrency
            // issues
            piece.cleanup(DISPOSE_DELAY * 1000);
        }, DISPOSE_DELAY * 1000);
    };
    if (stopDelay == 0) {
        stopFunction();
    } else {
        setTimeout(() => {
            stopFunction();
        }, stopDelay * 1000);
    }
};

Zen.PiecesPlayer.prototype._getIndex = function() {
    if (this._activeContext === null) {
        return null;
    }

    return this._activeContext.index;
};

Zen.PiecesPlayer.prototype._setActiveContext = function(piece, index, channel) {
    this._stateCounter += 1;
    if (index === null) {
        this._activeContext = null;
    } else {
        this._activeContext = new Zen.PieceContext(
            piece,
            index,
            channel
        );
    }
    this._onContextChange();
};

Zen.PiecesPlayer.prototype._onContextChange = function() {
    if (this._activeContext === null) {
        this.info('STATE: Stopped');
        // Cancel scheduled songs
        this._lastScheduled = 0;
    } else {
        this.info('STATE: Playing ' + this._activeContext.piece.getName());
        this._scheduleNextSong();
    }

    this._onStateChangeCallback();
};

Zen.PiecesPlayer.prototype._scheduleNextSong = function() {
    if (this._pieceInterval > 0) {
        let stateCounter = this._stateCounter;
        //var lastScheduled = Date.now();
        //this._lastScheduled = lastScheduled;
        setTimeout(() => {
            // If state changed in the meantime, just abort this call
            if (stateCounter != this._stateCounter) {
                this.info('State counter does not match, aborting next song');
                return;
            }
            this.info('Auto-advancing to next song');
            this.next();
        }, this._pieceInterval * 1000);
    }
};

export default Zen.PiecesPlayer;
