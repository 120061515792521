var beginningOfUniverse = Date.now();

/**
 *  @class  Zen is the base class of all other classes.
 *  @constructor
 */
var Zen = function(){
    if (!(this instanceof Zen)){
        throw new Error(
            "constructor needs to be called with the 'new' keyword");
    }

    this._uuid = this.uuid();
    this._className = 'Zen';
};

/**
 *  Test if the arg is undefined
 *  @param {*} arg the argument to test
 *  @returns {Boolean} true if the arg is undefined
 *  @static
 *  @memberOf Tone
 */
Zen.isUndef = function(val) {
    return typeof val === "undefined";
};

/**
 *  Have a child inherit all of parent's prototype
 *  to inherit the parent's properties, make sure to call
 *  Parent.call(this) in the child's constructor
 *
 *  based on closure library's inherit function
 *
 *  @memberOf Zen
 *  @static
 *  @param  {Function}     child
 *  @param  {Function=} parent (optional) parent to inherit from
 *                             if no parent is supplied, the child
 *                             will inherit from Zen
 */
Zen.extend = function(child, parent) {
    if (Zen.isUndef(parent)){
        parent = Zen;
    }
    function TempConstructor(){}
    TempConstructor.prototype = parent.prototype;
    child.prototype = new TempConstructor();
    /** @override */
    child.prototype.constructor = child;
    child._super = parent;
};

Zen.prototype.uuid = function() {
    return 'xxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * @param {String} msg Message to log
 */
Zen.prototype.format = function(msg) {
    var timestamp = Date.now() - beginningOfUniverse;
    var milli = '' + (timestamp % 1000);
    timestamp = Math.floor(timestamp / 1000);
    var secs = '' + (timestamp % 60);
    timestamp = Math.floor(timestamp / 60);
    var mins = '' + (timestamp % 60);
    var hours = '' + Math.floor(timestamp / 60);
    while (milli.length < 3) {
        milli = '0' + milli;
    }
    if (secs.length < 2) {
        secs = '0' + secs;
    }
    if (mins.length < 2) {
        mins = '0' + mins;
    }
    timestamp = hours + ':' + mins + ':' + secs + '.' + milli;

    return timestamp + ' [' + this._uuid + '] (' + this._className + ') ' + msg;
};

/**
 * @param {String} msg Message to log
 */
Zen.prototype.info = function(msg) {
    if (console) {
        console.log(this.format(msg));
    }
};

/**
 * @param {String} msg Message to log
 */
Zen.prototype.warn = function(msg) {
    if (console) {
        console.log(this.format(msg));
    }
};

/**
 * @param {String} msg Message to log
 */
Zen.prototype.error = function(msg) {
    if (console) {
        console.log(this.format(msg));
    }
};

export default Zen;
