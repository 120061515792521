import Tone from 'tone';
import { Distance, Note } from 'tonal';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Nakaii
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.Nakaii = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.Nakaii';

  this._name = 'Nakaii';
  this._artist = 'Alex Bainter';
  this._vinyl = 'nakaii';

  const findClosest = (note, samplesByNote) => {
    const noteMidi = Note.midi(note);
    const maxInterval = 96;
    let interval = 0;
    while (interval <= maxInterval) {
      const higherNote = Note.fromMidi(noteMidi + interval);
      if (samplesByNote[higherNote]) {
        return higherNote;
      }
      const lowerNote = Note.fromMidi(noteMidi - interval);
      if (samplesByNote[lowerNote]) {
        return lowerNote;
      }
      interval += 1;
    }
    return note;
  };

  this.getCustomSampler = (destination, samplesByNote, semitoneChange = 24) => {
    const activeSources = [];
    return Zen.MusicUtils.getBuffers(samplesByNote).then(buffers => ({
      triggerAttack: (note, time = Tone.now()) => {
        const closestSample = findClosest(note, samplesByNote);
        const difference = Distance.semitones(closestSample, note);
        const buffer = buffers.get(closestSample);
        const playbackRate = Tone.intervalToFrequencyRatio(
          difference - semitoneChange + Math.random() * 0.1 - 0.05
        );
        const bufferSource = new Tone.BufferSource(buffer)
          .set({
            playbackRate,
            onended: () => {
              const i = activeSources.indexOf(bufferSource);
              if (i >= 0) {
                activeSources.splice(i, 1);
              }
            },
          })
          .connect(destination);
        activeSources.push(bufferSource);
        bufferSource.start(time);
      },
      dispose: () => {
        [buffers, ...activeSources].forEach(node => node.dispose());
      },
    }));
  };

  const phraseProto = [
    ['C4'],
    ['C6'],
    ['B5'],
    ['D6', 'C6'],
    ['C6', 'B5'],
    ['A5', 'G5'],
    ['G5', 'F5'],
    ['B5', 'A5'],
    ['E5', 'G5'],
    ['C5'],
  ];

  this.getPhrase = () =>
    phraseProto.reduce((phrase, nextProtoNotes) => {
      const nextPossibleNotes = nextProtoNotes.filter(
        note => note !== phrase[phrase.length - 1]
      );
      return phrase.concat(
        nextPossibleNotes[Math.floor(Math.random() * nextPossibleNotes.length)]
      );
    }, []);
};

Zen.extend(Zen.Pieces.Nakaii, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.Nakaii.prototype._initPiece = function(destination) {
  const masterVol = new Tone.Volume(5).connect(destination);
  const volume = new Tone.Volume().connect(masterVol);
  const volLfo = new Tone.LFO(0.001, -100, -5).set({
    phase: 90,
  });
  volLfo.connect(volume.volume);
  volLfo.start();
  return Promise.all([
    this.getCustomSampler(masterVol, this._samples['vsco2-piano-mf']),
    this.getCustomSampler(volume, this._samples['vsco2-violins-susvib'], 36),
  ]).then(([piano, violins]) => {
    const playRandomPhrase = () => {
      if (!this._playing) {
        return;
      }
      let phrase = this.getPhrase();
      if (Math.random() < 0.5) {
        phrase = phrase.map(
          note => `${note[0]}${Number.parseInt(note[1], 10) + 1}`
        );
      }
      const multiplier = Math.random() + 1.75;
      phrase.forEach((note, i) => {
        const offset = Math.random() * 0.1 - 0.05 + 1;
        if (i <= 2) {
          if (this._playing) {
            piano.triggerAttack(note, `+${i * multiplier + offset}`);
          }
        } else if (i >= 3 && i <= 5) {
          if (this._playing) {
            piano.triggerAttack(
              note,
              `+${3 * multiplier + ((i - 3) * multiplier) / 3 + offset}`
            );
          }
        } else if (i < phrase.length - 1 || Math.random() < 0.95) {
          if (this._playing) {
            piano.triggerAttack(
              note,
              `+${4.5 * multiplier + ((i - 4.5) * multiplier) / 2 + offset}`
            );
          }
        }
      });

      if (this._playing) {
        Tone.Transport.scheduleOnce(() => {
          playRandomPhrase();
        }, `+${Math.random() * 5 + multiplier * phrase.length + 3}`);
      }
    };

    playRandomPhrase();

    ['C4', 'G3', 'C5'].forEach(note => {
      const play = () => {
        if (this._playing) {
          violins.triggerAttack(note, '+1');
        }

        if (this._playing) {
          Tone.Transport.scheduleOnce(() => {
            play();
          }, `+${Math.random() * 30 + 30}`);
        }
      };
      play();
    });

    this._cleanupPiece = () => {
      [masterVol, volume, volLfo, piano, violins].forEach(node =>
        node.dispose()
      );
    };
  });
};

export default Zen.Pieces.Nakaii;
