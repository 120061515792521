import Tone from 'tone';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Transmission
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.Transmission = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.Transmission';

  this._name = 'Transmission';
  this._artist = 'Alex Bainter';
  this._vinyl = 'transmission';

  this.transpose = (note, semitones) => {
    const pcTranspose = (note, semitones) => {
      const NOTES = [
        'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
      const currentIndex = NOTES.indexOf(note);
      const naiveIndex = currentIndex + semitones;
      const octaveChange = Math.floor(naiveIndex / 12);
      const realIndex =
        naiveIndex >= 0 ? naiveIndex % 12 : (12 + (naiveIndex % 12)) % 12;
      return [NOTES[realIndex], octaveChange];
    };

    const matches = /([A,B,C,D,E,F,G,#]{1,2})(\d*)/.exec(note);
    if (matches !== null) {
      // eslint-disable-next-line no-unused-vars
      const [_, pc, octaveStr] = matches;
      const [newPc, octaveChange] = pcTranspose(pc, semitones);
      if (octaveStr) {
        return `${newPc}${Number(octaveStr) + octaveChange}`;
      }
      return newPc;
    }
    return note;
  };

  this.TREMOLO_PATTERN = [
    true,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
  ];

  this.MIN_BPM = 40;
  this.MAX_BPM = 80;
};

Zen.extend(Zen.Pieces.Transmission, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.Transmission.prototype._initPiece = function(destination) {
  return Promise.all([
    Zen.MusicUtils.getSampler(this._samples['vsco2-piano-mf']),
    Zen.MusicUtils.getSampler(this._samples['vsco2-piano-mf']),
    new Tone.Reverb(15).set({ wet: 0.5 }).generate(),
  ]).then(([piano1, piano2, reverb]) => {
    var filter = new Tone.Filter().connect(reverb);
    var filterFreqLfo = new Tone.LFO(
      Math.random() / 1000 + 0.001,
      200,
      1000
    ).set({ phase: Math.random() * 360 });
    filterFreqLfo.connect(filter.frequency);
    filterFreqLfo.start();
    var volume = new Tone.Volume().connect(filter);
    var synth = new Tone.Synth().set({ volume: -25 }).connect(destination);
    reverb.connect(destination);

    const tremoloChord = (
      transposition = 0,
      bpm = Math.random() * (this.MAX_BPM - this.MIN_BPM) + this.MIN_BPM,
      up = Math.random() < 0.5
    ) => {
      if (!this._playing) {
        return;
      }
      const notes = ['C3', 'E3', 'G3', 'B3'].map(note =>
        this.transpose(note, transposition)
      );
      const [bassNote] = notes;
      if (!this._playing) {
        return;
      }
      synth.set({ envelope: { release: 60 / bpm } });
      if (this._playing) {
        synth.triggerAttackRelease(
          this.transpose(bassNote, -12),
          (60 / bpm) * 3,
          '+1'
        );
      };
      if (this._playing) {
        piano1.triggerAttack(notes, '+1');
      };

      notes
        .filter(() => Math.random() < 0.5)
        .forEach(note => {
          if (this._playing) {
            piano2.triggerAttack(
              this.transpose(note, 12),
              `+${Math.random() * ((60 / bpm) * 3 - 1) + 1}`
            );
          };
        });

        this.TREMOLO_PATTERN.forEach((isOn, i) => {
        volume.volume.setValueAtTime(
          isOn ? 0 : -50,
          `+${1 + i * (60 / bpm / 4)}`
        );
      });

      let semitoneChange = 0;
      if (Math.random() < 0.25) {
        if (transposition === -10) {
          semitoneChange = 5;
        } else if (transposition === 10) {
          semitoneChange = -5;
        } else {
          semitoneChange = Math.random() < 0.5 ? 5 : -5;
        }
      }

      let nextBpm;
      let nextUp = up;
      const pctDelta = Math.random() * 0.005;
      if (bpm <= this.MIN_BPM && !up) {
        nextBpm = bpm * (1 + pctDelta);
        nextUp = true;
      } else if (bpm >= this.MAX_BPM && up) {
        nextBpm = bpm * (1 - pctDelta);
        nextUp = false;
      } else if (up) {
        nextBpm = bpm * (1 + pctDelta);
      } else {
        nextBpm = bpm * (1 - pctDelta);
      }

      if (this._playing) {
        Tone.Transport.scheduleOnce(() => {
            tremoloChord(transposition + semitoneChange, nextBpm, nextUp);
        }, `+${(60 / bpm) * 4}`);
      }
    };

    tremoloChord();

    piano1.connect(volume);

    var chorus = new Tone.Chorus().connect(reverb);
    var chorusWetLfo = new Tone.LFO(Math.random() / 1000 + 0.001).set({
      phase: Math.random() * 360,
    });
    chorusWetLfo.connect(chorus.wet);
    chorusWetLfo.start();
    piano2.connect(chorus);

    this._cleanupPiece = () => {
      piano1.dispose();
      piano1 = null;
      piano2.dispose();
      piano2 = null;
      reverb.dispose();
      reverb = null;
      filter.dispose();
      filter = null;
      filterFreqLfo.dispose();
      filterFreqLfo = null;
      volume.dispose();
      volume = null;
      synth.dispose();
      synth = null;
      chorus.dispose();
      chorus = null;
      chorusWetLfo.dispose();
      chorusWetLfo = null;
    };
  });
};

export default Zen.Pieces.Transmission;
