import Tone from 'tone';
import { Distance, Note } from 'tonal';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Eyes Closed
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.EyesClosed = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.EyesClosed';

  this._name = 'Eyes Closed';
  this._artist = 'Alex Bainter';
  this._vinyl = 'eyes_closed';

  const findClosest = (samplesByNote, note) => {
    const noteMidi = Note.midi(note);
    const maxInterval = 96;
    let interval = 0;
    while (interval <= maxInterval) {
      const higherNote = Note.fromMidi(noteMidi + interval);
      if (samplesByNote[higherNote]) {
        return higherNote;
      }
      const lowerNote = Note.fromMidi(noteMidi - interval);
      if (samplesByNote[lowerNote]) {
        return lowerNote;
      }
      interval += 1;
    }
    return note;
  };

  this.getCustomSampler = (destination, samplesByNote, semitoneChange = -24) => {
    const activeSources = [];
    return Zen.MusicUtils.getBuffers(samplesByNote).then(buffers => ({
      triggerAttack: (note, time = '+1') => {
        if (buffers === null) {
          return;
        }
        const closestSample = findClosest(samplesByNote, note);
        const difference = Distance.semitones(closestSample, note);
        const buffer = buffers.get(closestSample);
        const playbackRate = Tone.intervalToFrequencyRatio(
          difference + semitoneChange
        );
        const source = new Tone.BufferSource(buffer)
          .set({
            playbackRate,
            onended: () => {
              const i = activeSources.indexOf(buffer);
              if (i >= 0) {
                let n = activeSources[i];
                activeSources.splice(i, 1);
                n.dispose();
              }
            },
          })
          .connect(destination);
        source.start(time);
      },
      dispose: () => {
        activeSources.forEach(node => node.dispose());
        buffers.dispose();
        buffers = null;
      },
    }));
  };

  this.PHRASE =
    [['G#5', 1], ['F#5', 2], ['D#5', 3.5], ['C#5', 4], ['D#5', 4.5]];
  this.CHORD = ['G#3', 'G#4'];
};

Zen.extend(Zen.Pieces.EyesClosed, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.EyesClosed.prototype._initPiece = function(destination) {
  var danTranhLfo = new Tone.AutoFilter(Math.random() / 100 + 0.01, 200);
  var pianoLfo = new Tone.AutoFilter(Math.random() / 100 + 0.01, 400);

  [danTranhLfo, pianoLfo].forEach(lfo => {
    lfo.connect(destination);
    lfo.start();
  });
  return Promise.all([
    Zen.MusicUtils.getBuffer(this._samples['dan-tranh-gliss-ps'][0]),
    this.getCustomSampler(pianoLfo, this._samples['vsco2-piano-mf']),
  ]).then(([danTranh, piano]) => {
    const playDanTranh = () => {
      if (!this._playing) {
        return;
      }

      const offset = Math.pow(Math.random(), 3) * 120;
      const duration = Math.random() * 60 + 60;
      const source = new Tone.BufferSource(danTranh)
        .set({
          fadeIn: 5,
          fadeOut: 5,
        })
        .connect(danTranhLfo);
      source.start('+1', offset, duration);
      if (this._playing) {
        Tone.Transport.scheduleOnce(() => {
          playDanTranh();
        }, `+${1 + duration - 5}`);
      }
    };

    playDanTranh();

    const schedulePhrase = () => {
      if (!this._playing) {
        return;
      }
      Tone.Transport.scheduleOnce(() => {
        if (!this._playing) {
          return;
        }
        const multiplier = Math.pow(Math.random(), 2);
        this.PHRASE.slice(0, Math.ceil(Math.random() * this.PHRASE.length)).forEach(
          ([note, time], i) => {
            if (this._playing) {
              piano.triggerAttack(
                note,
                `+${time * (1 + multiplier) + i * multiplier}`
              );
            }
          }
        );
        schedulePhrase();
      }, `+${Math.random() * 60 + 30}`);
    };
    schedulePhrase();

    const scheduleChord = () => {
      if (!this._playing) {
        return;
      }
      Tone.Transport.scheduleOnce(() => {
        this.CHORD.forEach(note => {
          if (this._playing) {
            piano.triggerAttack(note, `+${1 + Math.random() / 10 - 0.05}`);
          }
        });
        if (this._playing) {
          scheduleChord();
        }
      }, `+${Math.random() * 60 + 30}`);
    };

    scheduleChord();

    this._cleanupPiece = () => {
      danTranhLfo.dispose();
      danTranhLfo = null;
      pianoLfo.dispose();
      pianoLfo = null;
      danTranh.dispose();
      danTranh = null;
      piano.dispose();
      piano = null;
    };
  });
};

export default Zen.Pieces.EyesClosed;
