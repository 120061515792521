import Tone from 'tone';
import Zen from '../../core';
import '../../lib/music_utils'
import '../pieces';

/**
 *  @class Remembering
 *  @extends {Zen.Pieces.Piece}
 */
Zen.Pieces.Remembering = function (samples) {
  Zen.Pieces.Piece.call(this, samples);
  this._className = 'Zen.Pieces.Remembering';

  this._name = 'Remembering';
  this._artist = 'Alex Bainter';
  this._vinyl = 'remembering';

  const PITCH_CLASSES = ['C', 'D', 'E', 'G', 'A', 'C'];
  const getPhrase = octave => {
    const notes = PITCH_CLASSES.map(pc => `${pc}${octave}`).concat([
      `${PITCH_CLASSES[0]}${octave + 1}`,
    ]);
    return Array.from({ length: 4 }).map(
      () => notes[Math.floor(Math.random() * notes.length)]
    );
  };

  this.getPhrases = (octaves = [3, 4, 5, 6]) =>
    octaves.map(octave => getPhrase(octave));
};

Zen.extend(Zen.Pieces.Remembering, Zen.Pieces.Piece);

/**
 * Initializes Piece (and starts playing immediatelly)
 * @param {Tone.Channel} destination
 * @private
 */
Zen.Pieces.Remembering.prototype._initPiece = function(destination) {
  return Zen.MusicUtils.getSampler(this._samples['vsco2-piano-mf']).then(piano => {
    const reverb = new Tone.Freeverb(0.5)
      .set({ wet: 0.5 })
      .connect(destination);
    piano.connect(reverb);

    const playPhrase = () => {
      if (!this._playing) {
        return;
      }
      const phrases = this.getPhrases();
      const divisor = Math.random() * 0.15 + 0.5;
      phrases.forEach(phrase =>
        phrase.forEach((note, i) => {
          if (Math.random() < 0.85) {
            if (this._playing) {
              piano.triggerAttack(
                note,
                `+${i / divisor + Math.random() / 5 - 0.1}`
              );
            }
          }
        })
      );

      if (this._playing) {
        Tone.Transport.scheduleOnce(() => {
          playPhrase();
        }, `+${phrases[0].length / divisor + Math.random() * 5 + 3}`);
      }
    };
    playPhrase();

    this._cleanupPiece = () => {
      [piano, reverb].forEach(node => node.dispose());
    };
  });
};

export default Zen.Pieces.Remembering;
