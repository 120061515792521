import Zen from '../core';
import './pieces';

/**
 * @class Repository of Pieces, specifically, their constructors
 * @extends {Zen}
 */
Zen.Pieces.PiecesCollection = function () {
    Zen.call(this);
    this._className = 'Zen.Pieces.PiecesCollection';

    /**
     * List of focus pieces modules
     *  @type {Array}
     *  @private
     */
    this.focusPieces_ = [
        require('./focus/nakaii'),
        require('./focus/ritual'),
        require('./focus/eyes_closed'),
        require('./focus/soundtrack'),
        require('./focus/beneath_waves')
    ];

    /**
     * List of focus pieces modules
     *  @type {Array}
     *  @private
     */
    this.relaxPieces_ = [
        require('./focus/remembering'),
        require('./focus/uun'),
        require('./focus/stratospheric'),
        require('./focus/transmission')
    ];

    // The following have potential bugs in resource allocation
    // require('./focus/stream_of_consciousness'),
    // require('./focus/the_420hz_gamma_waves_for_big_brain'),
};

Zen.extend(Zen.Pieces.PiecesCollection);

/**
 *  Gets all 'focus' pieces
 *  @return {Array} List of piece constructors
 */
Zen.Pieces.PiecesCollection.prototype.getFocusPieces = function() {
    var pieces = [];
    for (var pieceModule of this.focusPieces_) {
        pieces.push(pieceModule.default);
    }
    return pieces;
};

/**
 *  Gets all 'relax' pieces
 *  @return {Array} List of piece constructors
 */
Zen.Pieces.PiecesCollection.prototype.getRelaxPieces = function() {
    var pieces = [];
    for (var pieceModule of this.relaxPieces_) {
        pieces.push(pieceModule.default);
    }
    return pieces;
};

export default Zen.Pieces.PiecesCollection;
