import Tone from 'tone';
import Zen from './zen/zen';
const { wav, mp3 } = Zen.MusicUtils.getSamplesByFormat('samples');

let state = null;
let lastPlayed = 0;
let buttonPrev = document.querySelector('#prev');
let buttonPlay = document.querySelector('#play');
let buttonNext = document.querySelector('#next');
let status = document.querySelector('#status');

let vinylBack = document.querySelector('#vinyl-back');
let vinylFront = document.querySelector('#vinyl-front');
let vinylSpinning = document.querySelector('#vinyl-spinning');

let backgroundContainer = document.querySelector('#background-containter');
let activeBackgroundTrack = null;

const toggleNavMenu = function() {
  let mainNav = document.getElementById('js-menu');
  let navBarToggle = document.getElementById('js-navbar-toggle');
  navBarToggle.addEventListener('click', function () {
      mainNav.classList.toggle('active');
  });
};

const enableBtn = function(btn) {
  btn.classList.remove('btn-disabled');
  btn.classList.add('btn-enabled');
};

const disableBtn = function(btn) {
  btn.classList.add('btn-disabled');
  btn.classList.remove('btn-enabled');
};

const isBtnEnabled = function(btn) {
  return btn.classList.contains('btn-enabled');
}

const enableAll = function() {
  enableBtn(buttonPrev);
  enableBtn(buttonNext);
  enableBtn(buttonPlay);
};

const disableAll = function() {
  disableBtn(buttonPrev);
  disableBtn(buttonNext);
  disableBtn(buttonPlay);
};

let zenPlayer = null;
let piecesPlayer = null;
let backgroundPlayer = null;

const vinylHelperSetter = function(name) {
  const f = function(n, id) {
    return 'url(\'images/vinyls/' + n + '/loop' + id + '.png\')';
  };

  vinylFront.style.backgroundImage = f(name, 1);
  vinylSpinning.style.backgroundImage = f(name, 2);
  vinylBack.style.backgroundImage = f(name, 3);
};

const setupPlayer = function(pieces) {
  zenPlayer = new Zen.ZenPlayer();
  zenPlayer.start();
  piecesPlayer = new Zen.PiecesPlayer(
    pieces,
    mp3,
    Tone.Master,
    15 * 60, /* autoNextInterval */
    () => {
      state = piecesPlayer.getState();
      let text = '';
      if (state.playing) {
        lastPlayed = state.pieceIndex;
        text = 'Playing ' + state.piece + ' by ' + state.artist;
        buttonPlay.classList.remove('bx-play');
        buttonPlay.classList.add('bx-stop');
        vinylHelperSetter(state.vinyl);
        enableAll();
      } else {
        text = 'Stopped';
        buttonPlay.classList.add('bx-play');
        buttonPlay.classList.remove('bx-stop');
        disableBtn(buttonPrev);
        disableBtn(buttonNext);
        enableBtn(buttonPlay);
      }
      status.innerHTML = text;
    },
  );
};

const setupPlayerButtons = function() {
  buttonPlay.addEventListener('click', () => {
    state = piecesPlayer.getState();
    if (!isBtnEnabled(buttonPlay)) {
      return;
    }
    disableBtn(buttonPlay);
    if (state.playing) {
      piecesPlayer.stop();
      enableBtn(buttonPlay);
      // stop spinner
      vinylSpinning.classList.remove('play');
    } else {
      piecesPlayer.playPiece(lastPlayed).then(() => {
      });
      // start spinner
      vinylSpinning.classList.add('play');
    }
  });

  buttonPrev.addEventListener('click', () => {
    if (!isBtnEnabled(buttonPrev)) {
      return;
    }
    disableAll();
    piecesPlayer.previous().then(() => {
    });
  });

  buttonNext.addEventListener('click', () => {
    if (!isBtnEnabled(buttonNext)) {
      return;
    }
    disableAll();
    piecesPlayer.next().then(() => {
    });
  });

  enableBtn(buttonPlay);

  //////// trooper hack
  backgroundPlayer = new Tone.Player().connect(Tone.Master);
  backgroundPlayer.loop = true;

  var backgroundButtons = [
    ['#background-rainfall', 'rainfall', -25],
    ['#background-fireplace', 'fireplace', -10],
    ['#background-cafe', 'cafe', -20],
    ['#background-forest', 'forest', -20],
    ['#background-vinyl', 'vinyl', -10],
  ];

  backgroundButtons.forEach(function (item) {
    var btn = document.querySelector(item[0]);
    btn.addEventListener('click', () => {
      if (btn === activeBackgroundTrack) {
        backgroundPlayer.stop();
        btn.classList.remove('background-item-active');
        activeBackgroundTrack = null;
        return;
      }
      let previousBackground = document.querySelector('.background-item-active');
      if (previousBackground) {
        previousBackground.classList.remove('background-item-active');
      }
      backgroundContainer.classList.add('avoid-clicks');
      backgroundPlayer.load('./background/' + item[1] + '.mp3').then(loaded => {
        backgroundPlayer.volume.value = item[2];
        backgroundPlayer.start();
        activeBackgroundTrack = btn;
        backgroundContainer.classList.remove('avoid-clicks');
        activeBackgroundTrack.classList.add('background-item-active');
      });
    });
  });
  //////// end of trooper hack
};

var initPlayer = function(path) {
  toggleNavMenu();
  disableAll();
  enableBtn(buttonPlay);
  var pieces = new Zen.Pieces.PiecesCollection();
  if (path === 'focus') {
    setupPlayer(pieces.getFocusPieces());
  } else if (path === 'relax') {
    setupPlayer(pieces.getRelaxPieces());
  } else {
    console.log('ERROR: Unknown path ' + path);
    return;
  }
  setupPlayerButtons();
};

var initAboutPopup = function() {
  const fileUrl = './about.html';
  fetch(fileUrl)
    .then(r => r.text())
    .then(content => {
      var textElement = document.getElementById('about-popup-text');
      textElement.innerHTML = content;
      document.setupTypeForm();
    });

  var popupContainer = document.getElementById('about-popup-container');
  var aboutButton = document.getElementById('about-button');
  var closeButton = document.getElementById('about-popup-close');

  aboutButton.onclick = function() {
    popupContainer.style.display = 'block';
  }

  closeButton.onclick = function() {
    popupContainer.style.display = 'none';
  }

  window.onclick = function(event) {
    if (event.target == popupContainer) {
      popupContainer.style.display = 'none';
    }
  }
};

document.setupTypeForm = function() {
    var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id='typef_orm_share', b='https://embed.typeform.com/';
    if (!gi.call(d,id)){ js=ce.call(d,'script'); js.id=id; js.src=b+'embed.js'; q=gt.call(d,'script')[0]; q.parentNode.insertBefore(js,q) } 
};

document.initZenPage = function(path) {
  initAboutPopup();
  if (path === 'index') {
    // Do nothing?
  } else {
    initPlayer(path);
  }
  document.setupTypeForm();
};
