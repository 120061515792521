import Tone from 'tone';
import Zen from './core';

/**
 * @class Player that ties it all together
 * @extends {Zen}
 */
Zen.ZenPlayer = function () {
    Zen.call(this);
    this._className = 'Zen.ZenPlayer';
};

Zen.extend(Zen.ZenPlayer);

Zen.ZenPlayer.prototype.start = function() {
    // Tone.context.latencyHint = 'playback';
    Tone.context.latencyHint = 'balanced';
    // Tone.context.lookAhead = 1;
    Tone.Transport.start();

    this.info('Tone latencyHint = ' + Tone.context.latencyHint);
    this.info('Tone lookAhead = ' + Tone.context.lookAhead);
};

export default Zen.ZenPlayer;
